const state = () => ({
	items: [],
});

// getters
const getters = {
	count(state) {
		return state?.items?.length || 0
	},
	items(state){
		return state?.items || []
	},
	inWishList: state => id => {
		if (!state?.items?.length) return false;
		return state.items.find(i => parseInt(i) === parseInt(id));
	},
}

// mutations
const mutations = {
	SET_RESULT(state, result) {
		state.items = result
	}
};

// actions
const actions = {

	async getAll({ commit }) {
		let items = (this._vm.$cookies.get('WISH_LIST') != 'undefined') ? this._vm.$cookies.get('WISH_LIST') : [];
		if(items.length > 0) items = JSON.parse(items);
		commit('SET_RESULT', items);
	},
	async toggle({ commit, state }, params) {
		let id = params?.id;
		if(!id) return  false;
		let items  = state.items;
		let current = items.indexOf(id);

		if(current > -1)
		{
			items = items.filter(item => item != id);
		}
		else
		{
			items.push(id)
		}
		this._vm.$cookies.set('WISH_LIST', JSON.stringify(items))
		this.dispatch('wishlist/getAll');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}