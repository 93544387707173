import ky from 'ky';
const mixin = {
	methods:{
		scrollIntoView(element) {
			if(!document.querySelector(element)) return false;
			document.querySelector(element).scrollIntoView({
				behavior: 'smooth'
			});

		},
		scrollTo(element){
			if(!element) return false;
			element = document.querySelector(element);

			window.scrollTo({
				top: element.getBoundingClientRect().top,
				behavior: 'auto'
			})
		},
		formatMoney(amount) {
			if(amount <=0) return;
			return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		},
		_$(code, format) {
			if(window._langTexts[code] !== undefined && window._langTexts[code].length > 0) {
				let text = window._langTexts[code];
				if(format) {
					for (i in format) {
						text = text.replace(i, format[i]);
					}
				}
				return text;
			} else {
				return code;
			}
		},
		api(component, action) {
			let route = `/bitrix/services/main/ajax.php?c=${component}&action=${action}&mode=class`;
			return {
				get(params) {
					if (params){
						let pr = new URLSearchParams();
						Object.keys(params).forEach(key => pr.append(key, params[key]))

						route += '&' + pr.toString();
					}

					return ky.get(route);
				},
				post(params) {
					return ky.post(route, {json: params});
				},
				sendForm(params) {
					const formData = new FormData();
					for(let i in params) {
						formData.append(i, params[i]);
					}
					return ky.post(route, { body: formData });
				},
			}
		},
		ajax() {
			return ky;
		}
	}
};
export default mixin
