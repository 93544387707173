(function(ELEMENT) {
	ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
	ELEMENT.closest = ELEMENT.closest || function closest(selector) {
		if (!this) return null;
		if (this.matches(selector)) return this;
		if (!this.parentElement) {return null}
		else return this.parentElement.closest(selector)
	};
}(Element.prototype));

class Drop {
	constructor(params) {
		document.addEventListener('click', e => this._onClick(e));
		this.parent = null;

		this.root = params.root;
		this.toggleClass = params.toggleClass;

		window.dropdown = {
			current:null,
			last:null
		}

	}
	getCurrent(){
		return window.dropdown.current;
	}
	setCurrent(e){

		if(window.dropdown.current != e)
		{
			window.dropdown.last = window.dropdown.current;
		}

		window.dropdown.current = e;
	}
	open(event){
	//	console.log('open')
		if(event) event.preventDefault();
		if (window.dropdown.last && window.dropdown.last != this.getCurrent()) {
			window.dropdown.last.classList.remove(this.toggleClass);
		}
		if(this.getCurrent()) this.getCurrent().classList.add(this.toggleClass);
	}
	close(){
	//	console.log('close')
		if(this.getCurrent()) this.getCurrent().classList.remove(this.toggleClass);

	}

	_onClick(event){

		const element = event.target;
		const parent = element.closest(this.root);
		console.log();
		if(parent){

			if(this.getCurrent() != parent) this.setCurrent(parent);

			if(!this.getCurrent().classList.contains(this.toggleClass)){
				this.open(event)
			}else{
				if(element.classList.contains('drop-btn') || element.classList.contains('drop-item') || element.closest('.close-on-select'))
				{
					setTimeout(e => this.close(), 300)
				}
			}

		}else{
			this.close();
		}

	}

}

export default Drop;
