document.addEventListener('DOMContentLoaded', () => {

	let debug = document.querySelector('.debug-modal')
	if(!debug)
	{
		debug = document.createElement('div');
		debug.classList.add('.debug-modal')
		Object.assign(debug.style,{
			'position': "fixed",
			'bottom': "10px",
			'left': "10px",
			'background': "#ffb4b4",
			'padding': '10px',
			'z-index': '1000',
			'border-radius': '5px',
			'font-size': '14px',
			'line-height': '1em'
		})
		debug.innerHTML = window.innerWidth + "x" + window.innerHeight;
		document.body.append(debug);
	}

	window.addEventListener('resize', e => {
		if(debug)	debug.innerHTML = window.innerWidth + "x" + window.innerHeight;
	})

})

