"use strict";

Array.prototype.max = function() {
	return Math.max.apply(null, this);
};

Array.prototype.min = function() {
	return Math.min.apply(null, this);
};

String.prototype.hashCode = function() {
	var hash = 0, i, chr, len;
	if (this.length === 0) return hash;
	for (i = 0, len = this.length; i < len; i++) {
		chr   = this.charCodeAt(i);
		hash  = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

(function(ELEMENT) {
	ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
	ELEMENT.closest = ELEMENT.closest || function closest(selector) {
		if (!this) return null;
		if (this.matches(selector)) return this;
		if (!this.parentElement) {return null}
		else return this.parentElement.closest(selector)
	};
}(Element.prototype));


(function(STRING) {
	STRING.declensions = function declensions(count, forms){
		var n = Math.abs(count) % 100; var n1 = n % 10;
		if (n > 10 && n < 20) { return forms[2]; }
		if (n1 > 1 && n1 < 5) { return forms[1]; }
		if (n1 == 1) { return forms[0]; }
		return forms[2];
	}
}(String.prototype));




Object.defineProperty(Array.prototype, 'chunk', {
	value: function(chunkSize){
		var temporal = [];

		for (var i = 0; i < this.length; i+= chunkSize){
			temporal.push(this.slice(i,i+chunkSize));
		}

		return temporal;
	}
});

window.isMobile = () => window.innerWidth < 808;
window.removeSpace = (s) => String(s).replace(/\s/g,'');
window.addSpace = (n) => String(window.removeSpace(n)).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
window.parseUrl = (href) => {
	let match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
	return match && {
		href: href,
		protocol: match[1],
		host: match[2],
		hostname: match[3],
		port: match[4],
		pathname: match[5],
		search: match[6],
		hash: match[7]
	}
}