const $body = document.querySelector('body');
let scrollPosition = 0;
let styles = {
//	"width": "100%",
//	"height": "100%",
	"overflow": "hidden",
//	"position": "fixed",
//	"-ms-scroll-chaining": "none",
//	"overscroll-behavior": "contain",
};

export default {
    disable() {
        $body.classList.add('no-scroll');

	//	scrollPosition = window.pageYOffset;

        Object.keys(styles).forEach(key => {
			$body.style[key] = styles[key];
		})


    },
    enable() {
   		$body.classList.remove('no-scroll');


		Object.keys(styles).forEach(key => {
			$body.style.removeProperty(key);
		})

	//	window.scrollTo(0, scrollPosition);
    },
	isDisabled(){
		return $body.classList.contains('no-scroll');
	}
};