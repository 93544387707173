import Vue from 'vue'
//import basket from './moduels/basket';
import wishlist from './moduels/wishlist';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
	//	basket,
		wishlist
	},
})