<template>
	<div>

	</div>
</template>

<script>
export default {
	name: 'loading-box',
	mounted() {

	}
}
</script>

<style lang="scss">
.loading-container{
	width: 100%;
	height: 100%;
	min-height: 250px;
	.loading{
		--width: 40px;
    	--color: var(--color-main);
		height: 100%;
		position: relative;
	}
}

</style>