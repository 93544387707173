//import "@fancyapps/ui/dist/fancybox.css";


import Vue from 'vue';
import ky from 'ky';
import { Laue } from 'laue';

import lazyLoadComponent from './utils/lazy-load-components';
import LoadingBox from '../vue-components/utils/LoadingBox.vue';
import mixins from '../vue-components/utils/mixins';
import store from './store/index.js';
import scroll from './helpers/scroll';
import Drop from './helpers/drop';

import VueCookies from 'vue-cookies'

window.ky = ky;
window.Drop = Drop;
document.scroll = scroll;


Vue.mixin(mixins);
Vue.use(Laue);
Vue.use(VueCookies)
Vue.$cookies.config('360d')

Vue.config.productionTip = false


const Components = {
	'modal':'popups/Modal',
	'notice':'popups/Notice',

	'icon':'ui/Icon',
	'select-multiple':'ui/SelectMultiple',

	'swiper':'global/Swiper',
	'graphic':'global/Graphic',
	'bars':'global/Bars',

	'wrap-text':'global/WrapText',
	'location-map':'global/LocationMap',


}

const ComponentsSkeleton = {

}

Object.keys(ComponentsSkeleton).forEach(k =>{
	Vue.component( k,  lazyLoadComponent({
			componentFactory: () => import('../vue-components/'+ComponentsSkeleton[k]),
			loading: LoadingBox,
		})
	);
});


Object.keys(Components).forEach(k => Vue.component( k, () => import('../vue-components/'+Components[k])));

new Vue({
	el: '#app',
	data:{

	},
	store: store,
	methods:{
		scrollIntoView(element) {
			if(!document.querySelector(element)) return false;
			document.querySelector(element).scrollIntoView({
				behavior: 'smooth'
			});
		},
		scrollTo(targetNode){

			if(!targetNode) return;

			const elementPosition = targetNode.getBoundingClientRect().top;

			window.scrollBy({
				top: elementPosition,
				behavior: 'smooth'
			});

		},

	},
	computed:{


	},
	mounted(){

		let btns = document.querySelectorAll('[data-emit]');

		btns.forEach(b => {
			b.addEventListener('click', () => {
				console.log(b.dataset.emit);
				this.$emit(b.dataset.emit)
			})
		})

	}
});


require('./helpers/debug')
require('./helpers/global')
require('./helpers/web')
require('./helpers/y-map')
