document.addEventListener('DOMContentLoaded', e => {

    new Drop({
        'root':'.drop',
        'toggleClass':'--open',
    });


    let hBtns = document.querySelectorAll('.aside__nav_toggle');
    if(hBtns)
    {
        hBtns.forEach(b => {
            console.log(b);
            b.addEventListener('click', e => document.body.classList.toggle('aside-nav-visible'))
        })
    }



    (function () {
        document.body.style.setProperty('--header-height', document.querySelector('.header').clientHeight + 'px');
        document.body.style.setProperty('--footer-height', document.querySelector('.footer').clientHeight + 'px');
    })();


})